<template>
    <div class="warp">
        <div class="header">
            <van-nav-bar :title="title" left-text="" left-arrow fixed @click-left="goBack" />
        </div>

        <div class="bodyWarp">
            <div class="sidebarWarp">
                <van-sidebar v-model="activeKey" @change="onChange">
                    <van-sidebar-item v-for="item in list" :key="item.firstId" :title="item.firstName" />
                </van-sidebar>
            </div>

            <div class="rightWarp">
                <ul class="clear">
                    <li class="active" v-for="item in activeObj" :key="item.secondId" @click="goTo(item)">
                        <img class="titleNarrow" :src="item.secondIconUrl" />
                        <div class="title">{{ item.secondName }}</div>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
import { firstOrSecondList } from "@/api/questionBank.js";

export default {
    data() {
        return {
            title: '在线题库',
            list: [],
            activeKey: 0,
            activeObj: []
        }
    },
    name: 'directionTab',
    methods: {
        goBack() {
            // this.$router.go(-1);
            this.$router.push('/')
        },
        onChange(index) {
            this.activeObj = this.list[index].secondList;
        },
        goTo(item) {
            this.$store.commit('setStudyList', { secondId: item.secondId });
            window.localStorage.setItem('secondId', item.secondId)
            // this.$router.push('questionBank');
            this.$emit('showHome');
        },
        firstOrSecondList() {
            firstOrSecondList().then((res) => {
                if (res.data.code === 0) {
                    this.list = res.data.data;
                    this.activeObj = this.list[0].secondList;
                }
            })
        }
    },
    created() {
        this.firstOrSecondList();
    },
    mounted() { }
}
</script>

<style lang="less" scoped>
.header {
    font-size: 17px;
    font-weight: 500;
    color: #141414;
    line-height: 24px;
}

/deep/ .van-nav-bar__title {
    font-size: 17px;
    font-weight: 500;
    color: #141414;
}

/deep/ .header .van-icon-arrow-left {
    color: #000;
}

.bodyWarp {
    display: flex;
    margin-top: 42px;
    background: #f8f8f8;
}

.sidebarWarp {
    width: 102px;
    height: calc(100vh - 46px);
}

/deep/ .van-sidebar {
    width: 102px;
    text-algin: center;
    height: calc(100vh - 46px);
    background: #fff;
}

/deep/ .van-sidebar-item {
    padding: 0 12px;
    background: #fff;
}

/deep/ .van-sidebar-item__text {
    height: 45px;
    line-height: 45px;
    font-size: 14px;
}

/deep/ .van-sidebar-item--select::before {
    height: 45px;
    background-color: #5f7dff;
}

/deep/ .van-sidebar-item--select,
.van-sidebar-item--select:active {
    background: #f8f8f8;
}

.rightWarp {
    flex: 1;
    padding: 20px 16px;
}

.clear {
    clear: both;
    content: "";
    overflow: hidden;
}

.active {
    width: 116px;
    height: 85px;
    background: #fefefe;
    border-radius: 7px;
    float: left;
    text-align: center;
    margin-bottom: 9px;
}

.active:nth-child(even) {
    margin-left: 7px;
}

.titleNarrow {
    width: 29px;
    height: 20px;
    margin: 24px auto 0;
}

.title {
    height: 17px;
    margin: 6px auto 0;
    font-size: 12px;
    color: #666666;
    line-height: 17px;
}
</style>
